<template>
  <div class="mx-6 flex flex-col gap-4 md:mx-18 md:gap-8">
    <div class="flex items-center gap-6">
      <form
        class="forms grow grid-cols-4 gap-x-5 gap-y-8 md:hidden"
        @submit.prevent="addFilter"
      >
        <div class="relative md:col-span-3">
          <input
            v-model="form.query"
            type="text"
            :placeholder="i18n.trans('Найти...')"
            class="pr-14"
            @input="addFilter($event, false)"
          >
          <button
            type="submit"
            class="absolute right-4 top-1/2 -mt-3"
          >
            <SearchSvg class="text-neutral-50" />
          </button>
        </div>
      </form>
      <FilterForm
        :form="form"
        :sort-options="sortOptions"
        class="max-md:hidden"
        @add-filter="addFilter"
        @reset-form="resetForm"
      />
      <button
        type="button"
        class="size-6 grow-0 md:hidden"
        @click="showFiltersModal = true"
      >
        <FilterSvg />
      </button>
    </div>
    <ul class="flex flex-wrap gap-x-4 gap-y-2 text-xs leading-none md:hidden">
      <li>
        {{ sortOption.label }}
        <a
          role="button"
          @click="resetSorting"
        >
          <XCloseSvg class="inline" />
        </a>
      </li>
      <li
        v-for="(category, index) in selectedCategories"
        :key="`category-${category.id}`"
      >
        {{ category.name }}
        <a
          role="button"
          @click="removeCategory(index)"
        >
          <XCloseSvg class="inline" />
        </a>
      </li>
      <li
        v-for="(city, index) in selectedCities"
        :key="`city-${city.id}`"
      >
        {{ city.name }}
        <a
          role="button"
          @click="removeCity(index)"
        >
          <XCloseSvg class="inline" />
        </a>
      </li>
      <li
        v-for="(venue, index) in selectedVenues"
        :key="`venue-${venue.id}`"
      >
        {{ venue.title }}
        <a
          role="button"
          @click="removeVenue(index)"
        >
          <XCloseSvg class="inline" />
        </a>
      </li>
    </ul>
  </div>
  <div class="mx-6 mt-8 flex flex-col gap-4 md:mx-18 md:gap-8">
    <ul class="flex flex-col gap-4 md:flex-row md:flex-wrap md:gap-x-5 md:gap-y-8">
      <li
        v-for="timetable in timetables"
        :key="`search-timetable-${timetable.id}`"
        class="relative flex shrink flex-row gap-3 overflow-hidden max-md:rounded-lg max-md:shadow-lg md:max-w-62 md:basis-1/4 md:flex-col"
      >
        <div
          class="absolute left-2 top-2 z-10 hidden rounded bg-danger p-1 text-xs leading-none text-white shadow"
          :class="{'hidden': timetable?.isSoldOut }"
        >
          Sold Out
        </div>
        <div
          class="z-1 absolute inset-0 hidden bg-white/40"
          :class="{'hidden': timetable?.isSoldOut }"
        />

        <a
          :href="route('front.events.view', {
            city: timetable.city.slug,
            category: timetable.event.category.slug,
            event: timetable.event.slug,
            timetable: timetable.id
          })"
          class="group flex shrink grow gap-2 md:gap-3 overflow-hidden no-underline max-md:max-h-42 max-md:min-w-39 md:max-w-62 md:basis-1/4 md:flex-col text-primary-600"
        >
          <div class="relative overflow-hidden max-md:max-h-42 max-md:min-w-56 md:rounded-lg">
            <img
              :src="timetable.event.image"
              class="w-full max-md:hidden"
              :alt="timetable.event.seo_image_alt"
              :title="timetable.event.seo_image_title"
            />
            <img
              :src="timetable.event.mobileSearchImage"
              class="w-full md:hidden"
              :alt="timetable.event.seo_image_alt"
              :title="timetable.event.seo_image_title"
            />
          </div>

          <div
            class="flex flex-col gap-2 md:gap-3 max-md:max-w-30 max-md:py-4 leading-4 md:leading-6 text-xs md:text-base"
          >
            <h4
              class="uppercase font-extrabold text-xs md:text-base group-hover:text-primary-600 text-primary-600 text-ellipsis overflow-hidden h-8 md:h-12"
            >
              {{ timetable.eventName }}
            </h4>
            <div class="flex flex-col gap-1">
              <div class="flex gap-2 items-center">
                <LocationSvg class="size-3 stroke-primary-600 md:size-4 max-md:hidden" />
                <p>{{ timetable.eventCityName }}</p>
              </div>
              <div class="flex gap-2 items-center">
                <CalendarSvg class="size-3 stroke-primary-600 fill-none md:size-4 max-md:hidden" />
                <p>{{ timetable.starts_at }}</p>
              </div>
            </div>
            <p
              class="font-bold uppercase"
              v-html="timetable.minPriceText"
            />
          </div>
        </a>
      </li>
    </ul>

    <button
      v-if="showMore"
      class="btn btn-outline"
      type="button"
      @click="loadMore"
    >
      {{ i18n.trans('Показать еще 24') }}
    </button>
  </div>

  <div class="md:hidden">
    <Dialog
      v-model:visible="showFiltersModal"
      modal
      @close="showFiltersModal=false"
    >
      <template #container>
        <div class="forms flex w-92 flex-col gap-6 bg-white px-6 py-4 shadow-xl">
          <div class="flex items-center gap-4">
            <FilterSvg />
            <h1 class="grow text-xl">
              {{ i18n.trans('Фильтры') }}
            </h1>
            <button
              type="button"
              class="size-6"
              @click="showFiltersModal = false"
            >
              <XCloseSvg />
            </button>
          </div>

          <FilterForm
            :form="form"
            :sort-options="sortOptions"
            @add-filter="addFilter"
            @reset-form="resetForm"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import useForm from '@/Front/Composables/useForm.js';
import useTranslations from '@/Front/Composables/useTranslations.js';
import { useApp } from '@/Front/Stores/useApp';
import CalendarSvg from 'images/front/icons/calendar.svg';
import FilterSvg from 'images/front/icons/filter.svg';
import LocationSvg from 'images/front/icons/location.svg';
import SearchSvg from 'images/front/icons/search.svg';
import XCloseSvg from 'images/front/icons/x-close.svg';
import { debounce } from 'lodash';
import { usePrimeVue } from 'primevue/config';
import Dialog from 'primevue/dialog';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import FilterForm from './Filters.vue';

const filters = window.$app.filters;
const i18n = useTranslations();
const timetables = ref([]);

const appStore = useApp();
const primevue = usePrimeVue();
axios.get(`/Lang/${appStore.locale.current}.json`)
  .then(response => {
    primevue.config.locale = response.data;
  });

const form = useForm({
  query: filters.request?.query ?? '',
  categories: filters.request?.categories ?? [],
  cities: filters.request?.cities ?? [],
  venues: filters.request?.venues ?? [],
  sorting: filters.request?.sorting ?? 'soon',
  dates: filters.request?.dates ?? [],//?.map(date => new Date(date)) ?? [],
  page: 0,
});

const sortOptions = [
  { label: i18n.trans('От новых к старым'), value: 'desc' },
  { label: i18n.trans('От старых к новым'), value: 'asc' },
  { label: i18n.trans('По популярности'), value: 'popular' },
  { label: i18n.trans('Ближайшие'), value: 'soon' },
];

const sortOption = computed(() => sortOptions.find(option => option.value === form.sorting));
const selectedCategories = computed(() => filters.categories.filter(category => form.categories.includes(category.id)));
const selectedCities = computed(() => filters.cities.filter(city => form.cities.includes(city.id)));
const selectedVenues = computed(() => filters.venues.filter(venue => form.venues.includes(venue.id)));

const showMore = ref(timetables.value.length > 24);

function resetSorting() {
  form.sorting = 'soon';
  addFilter();
}

function removeCategory(index) {
  form.categories.splice(index, 1);
  addFilter();
}

function removeCity(index) {
  form.cities.splice(index, 1);
  addFilter();
}

function removeVenue(index) {
  form.venues.splice(index, 1);
  addFilter();
}

const showFiltersModal = ref(false);

const resizeListener = () => {
  if (window.matchMedia('(min-width: 576px)').matches) {
    showFiltersModal.value = false;
  }
};
onMounted(() => {
  window.addEventListener('resize', resizeListener);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeListener);
});

function resetForm() {
  form.query = '';
  form.categories = [];
  form.cities = [];
  form.venues = [];
  form.sorting = 'soon';
  form.dates = [];
  form.page = 0;
  addFilter();
}

function loadMore() {
  form.page++;
  addFilter(true);
}

const addFilter = debounce((append = false) => {
  let formData = form.data();
  delete formData.page;

  const url = route('front.search.index', { region: window.$app.region, ...formData });
  window.history.replaceState({}, '', url);

  axios.get(route('front.search.load', { region: window.$app.region, ...form.data() }))
    .then(({ data }) => {
      if (append === true) {
        timetables.value = [...timetables.value, ...data.data];
      } else {
        timetables.value = data.data;
      }

      showMore.value = form.page < data.meta.last_page;
    });
}, 500);

onMounted(loadMore);
</script>
